<template>
  <v-card
    v-if="callToAction.show"
    @click="callToAction.action"
    :max-width="callToAction.maxWidth"
    elevation="0"
    class="align-center justify-center"
  >
    <v-card-text class="d-flex align-center justify-space-between">
      <v-icon
        v-if="callToAction.icon"
        class="pa-3 background-shadow-icon"
        color="#F39C12"
        left
      >
        {{ callToAction.icon }}</v-icon
      >

      <div v-if="callToAction.img" class="pa-3 mr-2 background-shadow-img">
        <v-img
          :src="callToAction.img"
          contain
          max-width="27"
          max-height="27"
        ></v-img>
      </div>

      <div class="ml-2 font-poppins">
        <p class="mb-1 font-poppins title-1">{{ callToAction.title }}</p>
        <p class="mb-0 cta-description">{{ callToAction.description }}</p>
      </div>

      <v-icon large right>mdi-chevron-right</v-icon>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  redirectToTheCommercialManagerGoogleCalendar,
  goToControl,
} from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "CTACall",
  props: {
    action: { type: String, required: true },
  },
  data: () => ({}),

  computed: {
    ...mapGetters([
      "isSmallClient",
      "showScheduleMeeting",
      "company",
      "hasConectaControl",
      "currentUser",
    ]),

    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },

    callToAction() {
      const callsToActions = {
        meeting: {
          icon: "ph-video-camera",
          title: this.$t("newDashboard.meeting"),
          description: this.$t("newDashboard.meetingDescription"),
          action: this.scheduleMeeting,
          maxWidth: 320,
          show: !this.isSmallClient && this.showScheduleMeeting,
        },
        controlCTA: {
          img: require("@/assets/appslogos/icons/icon-control.png"),
          title: this.hasConectaControl
            ? this.$t("newDashboard.goTocontrolCTA")
            : this.$t("newDashboard.upsellToControlCTA"),
          description: this.$t("newDashboard.controlCTADescription"),
          action: this.hasConectaControl ? goToControl : this.goToSelfCheckout,
          maxWidth: 350,
          show: !this.showScheduleMeeting,
        },
      };

      return callsToActions[this.action];
    },
  },

  methods: {
    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened new dashboard card";
      const clicked_from = "Botão de agendar reunião (Dash)";

      logEvent(analytics, "new dashboard card", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },

    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "cta_dashboard",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: "CTA da página inicial do novo dash",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },
  },
};
</script>
<style scoped>
.background-shadow-icon {
  background-color: #fdebd0;
  border-radius: 100%;
}

.background-shadow-img {
  background-color: #ebf5fc;
  border-radius: 100%;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.title-1 {
  font-weight: 500;
  color: black;
  font-size: 0.88rem;
  line-height: 24.5px;
}

.cta-description {
  font-size: 0.75rem;
  line-height: 14.7px;
}
</style>
