<template>
  <v-data-table
    :headers="headers"
    :items="usersToDisplay"
    :items-per-page="5"
    :show-select="!!usersToDisplay.length"
    item-key="key"
    :loading="loading || loadingSign"
    :hide-default-header="hideHeader"
    fixed-header
    height="224"
    class="elevation-0 pt-4 pb-2 rounded-lg table-font"
  >
    <template v-slot:top>
      <div class="d-flex align-center px-4">
        <div class="titles-container">
          <h2 class="title">{{ $t("common.collaborators") }}</h2>
          <p class="subtitle mb-4">
            {{ $t("usersTableMetrics.subtitle") }}
          </p>
        </div>
        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <v-text-field
            v-model="search"
            class="mx-2 search"
            flat
            dense
            hide-details
            :label="$t('common.search')"
            solo
            background-color="#fff"
            id="serch-metrics"
          >
            <template v-slot:prepend-inner>
              <v-icon size="16" left>ph-magnifying-glass</v-icon>
            </template>
          </v-text-field>

          <v-btn class="text-none cell-font" text elevation="0">
            <v-icon color="#6D6D6D" left size="15">ph-download-simple</v-icon>
            <span class="export-btn">{{ $t("action.export") }}</span>
          </v-btn>
        </div>
      </div>

      <v-item-group mandatory v-model="selectedMenuTableIndex" class="px-4">
        <div
          class="tab-line mb-4 mt-2 px-4 d-flex align-center justify-space-between"
        >
          <div v-for="(value, key) in tableMenu" :key="key" class="py-2">
            <v-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? '#bb6bd933' : ''"
                class="d-flex align-center justify-center menu-border"
                elevation="0"
                min-height="31"
                @click="toggle"
              >
                <span
                  :class="`menu-text ${
                    active ? 'active-text' : 'inactive-text'
                  }`"
                >
                  {{ $t(`usersTableMetrics.${value}`) }}</span
                >
              </v-card>
            </v-item>
          </div>
        </div>
      </v-item-group>
    </template>

    <template v-slot:no-data>
      <div>
        <v-avatar color="#F1E1F7E5" size="64" class="mt-6">
          <v-icon color="#6D6D6D" size="32" dark v-text="'ph-shield-warning'" />
        </v-avatar>
        <div class="mt-3 poppins-14-21 text-center" style="color: #6d6d6d">
          {{ $t(emptyState[activeMenu].noData) }}
        </div>
        <div class="mt-2 poppins-12-18 text-center" style="color: #979797">
          {{ $t(emptyState[activeMenu].cta) }}
        </div>
        <div>
          <v-btn
            class="mt-5 px-3"
            color="#BB6BD933"
            depressed
            @click="emptyState[activeMenu].method"
          >
            <span
              class="poppins-12-18 text-left"
              style="color: #5b36c3; text-transform: none"
            >
              {{ $t(emptyState[activeMenu].action) }}
            </span>
            <v-icon right dark color="#5B36C3"> ph-arrow-right </v-icon>
          </v-btn>
        </div>
      </div>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <v-row class="align-center py-2" no-gutters>
        <v-avatar left size="28" class="mr-2">
          <img :src="item.photo" :alt="item.name" />
        </v-avatar>
        <span class="text-capitalize cell-font">{{ item.name }}</span>
      </v-row>
    </template>

    <template v-slot:[`item.email`]="{ item }">
      <span class="cell-font">{{ item.email }}</span>
    </template>

    <template v-slot:[`item.departament`]="{ item }">
      <span class="cell-font">{{ item.departament }}</span>
    </template>

    <template v-slot:[`item.applied_signature_key`]="{ item }">
      <span class="cell-font">
        {{ getSignatureModelName(item.applied_signature_key) }}
      </span>
    </template>

    <template v-slot:[`item.applied_vacation_key`]="{ item }">
      <span class="cell-font">
        {{ getVacationModelName(item.applied_vacation_key) }}
      </span>
    </template>

    <template v-slot:[`item.vacation_end_date`]="{ item }">
      <span class="cell-font">
        {{ formatDate(item.vacation_end_date) }}
      </span>
    </template>

    <template v-slot:[`item.permission`]="{ item }">
      <ul class="py-2">
        <li
          class="cell-font"
          v-for="(_, value) in getAdminPermissions(item)"
          :key="value"
        >
          {{ $t(`permissions.${value}`) }}
        </li>
      </ul>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatDate,
  checkExpiredDate,
  goToControl,
} from "@/helpers/services/utils";

export default {
  name: "UsersTableMetrics",
  data() {
    return {
      search: "",
      selectedMenuTableIndex: 0,
      tableMenu: [
        "signature",
        "permissions",
        "vacation",
        "delegates",
        "vacationControl",
        "shifts",
      ],
      controlUsers: null,

      emptyState: {
        signature: {
          noData: "userInformations.emptyState.signature.noData",
          cta: "userInformations.emptyState.signature.cta",
          action: "action.applySignature",
          method: () => this.routerPush("/sign/email-signature/templates"),
        },
        permissions: {
          noData: "userInformations.emptyState.permissions.noData",
          cta: "userInformations.emptyState.permissions.cta",
          action: "userInformations.emptyState.permissions.action",
          method: () => this.routerPush("/settings/user-permissions"),
        },
        vacation: {
          noData: "userInformations.emptyState.vacation.noData",
          cta: "userInformations.emptyState.vacation.cta",
          action: "action.applyVacationMessage",
          method: () => this.routerPush("/sign/vacation-message"),
        },
        delegates: {
          noData: "userInformations.emptyState.delegates.noData",
          cta: "userInformations.emptyState.delegates.cta",
          action: "userInformations.emptyState.delegates.action",
          method: () => this.routerPush("/users"),
        },
        vacationControl: {
          noData: "userInformations.emptyState.vacationControl.noData",
          cta: "userInformations.emptyState.vacationControl.cta",
          action: "action.configVacationMessage",
          method: () => goToControl("users"),
        },
        shifts: {
          noData: "userInformations.emptyState.shifts.noData",
          cta: "userInformations.emptyState.shifts.cta",
          action: "userInformations.emptyState.shifts.action",
          method: () => goToControl("groups"),
        },
      },
    };
  },

  methods: {
    formatDate,

    ...mapActions([
      "getDefaultTemplates",
      "getCustomTemplates",
      "getVacationTemplates",
    ]),

    getSignatureModelName(signature_id) {
      if (!signature_id) return "-";

      const signTemplate = this.allTemplates.find(
        (template) => template.id === signature_id
      );

      return signTemplate ? signTemplate.name : "-";
    },

    getAdminPermissions(user) {
      return Object.keys(user.permission).reduce((acc, key) => {
        if (
          user.permission[key] === "admin" &&
          ![
            "conecta_ad_sync",
            "conecta_control_mobile",
            "integracao_ponto_mais",
          ].includes(key)
        ) {
          acc[key] = "admin";
        }
        return acc;
      }, {});
    },

    getVacationModelName(vacation_key) {
      if (!vacation_key) return "-";

      const vacationTemplate = this.allVacationTemplates.find(
        (template) => template.key === vacation_key
      );

      return vacationTemplate ? vacationTemplate.name : "-";
    },

    routerPush(path) {
      this.$router.push(path);
    },
  },

  computed: {
    ...mapGetters([
      "loading",
      "loadingSign",
      "users",
      "allTemplates",
      "allVacationTemplates",
    ]),

    availableHeaders() {
      return {
        signature: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("usersTableMetrics.signModel"),
            value: "applied_signature_key",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
        ],
        permissions: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("sidebar.permissions"),
            value: "permission",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
        ],
        vacation: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("usersTableMetrics.signModel"),
            value: "applied_vacation_key",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
          {
            text: this.$t("userInformations.endDate"),
            value: "vacation_end_date",
            align: "start",
          },
        ],
        delegates: [],
        vacationControl: [],
        shifts: [],
      };
    },

    activeMenu() {
      return this.tableMenu[this.selectedMenuTableIndex];
    },

    headers() {
      return this.availableHeaders[this.activeMenu];
    },

    usersToDisplay() {
      const usersPerMenu = {
        signature: this.usersWithSignApplied,
        permissions: this.usersWithPermission,
        vacation: this.usersWithVacationApplied,
        delegates: [],
        vacationControl: [],
        shifts: [],
      };

      return usersPerMenu[this.activeMenu];
    },

    usersWithSignApplied() {
      return this.users.filter((user) => user.applied_signature_key);
    },

    usersWithPermission() {
      return this.users.filter((user) =>
        Object.values(user.permission).includes("admin")
      );
    },

    usersWithVacationApplied() {
      return this.users.filter(
        (user) =>
          user.applied_vacation_key && !checkExpiredDate(user.vacation_end_date)
      );
    },

    hideHeader() {
      return !this.usersToDisplay.length; // || (TODO regras dos planos)
    },
  },

  beforeMount() {
    if (!this.allTemplates.length) {
      this.getDefaultTemplates();
      this.getCustomTemplates();
    }
    if (!this.allVacationTemplates.length) {
      this.getVacationTemplates();
    }
  },
};
</script>
<style scoped>
.search {
  border-radius: 16px;
  width: 184px;
  border: 1px solid #dbdbdb;
}

.titles-container {
  max-width: 406.63px;
}

.export-btn {
  color: #6d6d6d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.title {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 8px 0 6px 0;
}

.subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #696969;
}

.menu-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.active-text {
  color: #5b36c3;
}

.inactive-text {
  color: #979797;
}

.tab-line {
  border: 1px solid #5b36c354;
  border-radius: 10px;
  margin: 0px 1px;
}

.menu-border {
  border-radius: 4px !important;
  padding: 6px 13px !important;
}

::v-deep .v-data-table-header th {
  background-color: white !important;
}

::v-deep .v-data-footer div {
  color: #696969;
  font-family: "Poppins", sans-serif;
}

::v-deep
  .search
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#serch-metrics {
  padding: 0px;
  font-size: 12px;
}

::v-deep
  .search
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#serch-metrics::content {
  color: #747474;
  font-size: 12px;
}

::v-deep .search .v-input__control .v-input__slot .v-text-field__slot label {
  color: #747474;
  font-size: 12px;
  display: block;
  text-align: center;
  line-height: 150%;
}

.table-font {
  font-family: "Poppins", sans-serif;
}

.cell-font {
  font-size: 12px;
}

.poppins-12-18 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.poppins-14-21 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
</style>

<style>
.table-font .v-data-table__progress {
  position: sticky;
  top: 48px;
  z-index: 1;
}
</style>
