<template>
  <v-card
    elevation="0"
    class="cards pb-3"
    height="184px"
    width="212px"
    max-width="212px"
  >
    <v-card-title :style="`color: ${card.isEmpty ? '#979797' : ''}`">
      <v-icon
        size="30"
        class="mr-2"
        :color="`${card.isEmpty ? '#979797' : '#5B36C3'}`"
      >
        {{ card.icon }}
      </v-icon>
      <span class="card-font" style="color: #696969 !important">
        {{ card.title }}
      </span>
    </v-card-title>
    <v-card-subtitle class="card-font py-0">
      <div class="d-flex mt-5">
        <span
          class="mr-2"
          :style="`
            height: 29px;
            font-family: Montserrat;
            font-size: 24px;
            ${card.isEmpty ? 'color: #979797' : 'color: black;'}`"
        >
          {{ isEmptyState ? card.emptyStateDefaultValue : getCountUsers(card) }}
        </span>
        <v-chip
          color="#DEFFE2"
          class="card-subtitle"
          :style="`color: ${card.isEmpty ? '#979797' : '#696969'}`"
          >{{ $t("common.users") }}</v-chip
        >
      </div>
    </v-card-subtitle>
    <v-card-text
      v-if="card.isEmpty"
      class="py-0 d-flex justify-center align-center flex-column"
    >
      <p
        class="py-0 my-0 card-font"
        :style="`color: ${card.isEmpty ? '#979797' : ''}`"
      >
        {{ card.description }}
      </p>
      <div style="margin-top: 5px">
        <v-btn
          color="#F1E1F7"
          class="text-none text-body-2 font-weight-medium pa-0 mx-0 ma-0 btns"
          @click="card.emptyAction()"
          elevation="0"
        >
          <span class="text-empty-state ml-3"
            >{{ $t(`action.${card.emptyActionText}`) }}
          </span>
          <v-icon right class="mx-3" size="16">ph-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text v-else class="py-0">
      <p
        class="py-0 my-0 card-font"
        :style="`color: ${card.isEmpty ? '#979797' : ''}`"
      >
        {{ card.description }}
      </p>
      <div
        v-if="card.percentValues"
        style="position: absolute; bottom: 6px; width: 84%"
      >
        <v-progress-linear
          :color="`${card.isEmpty ? '#979797' : '#5B36C3'}`"
          class="white--text"
          :value="card.percentValues"
          height="5"
          rounded
          style="margin-bottom: 7px !important"
        ></v-progress-linear>
        <span
          v-html="card.subDescription"
          class="card-sub-description"
          style="font-family: Montserrat"
        ></span>
      </div>
      <div
        v-else-if="!!card.users?.length"
        class="d-flex align-start justify-start"
        style="position: absolute; bottom: 5px"
      >
        <div v-for="(user, index) in card.users" :key="index">
          <img
            :src="user.photo"
            :class="[
              'card-user-photo',
              { 'card-user-photo-offset': index > 0 },
            ]"
            v-if="index < 5"
          />
          <div
            v-if="index === 5"
            class="card-user-total-length white--text d-flex justify-center align-center font-weight-medium"
          >
            +{{ card.users.length - 1 }}
          </div>
        </div>
      </div>
      <!-- <div style="position: absolute; bottom: 12px; left: 29px">
        <v-btn
          color="white"
          class="mx-auto text-none text-body-2 font-weight-medium px-2 pa-0 mx-0 ma-0 btns"
          @click="
            execute(
              `${card.emptyActionText}_indicator`,
              '/billing/upgrade',
              'Fazer upgrade'
            )
          "
        >
          <span class="text-empty-state">{{ $t("sidebar.upgradeCTA") }} </span>
          <v-icon right class="ml-3" size="18">ph-arrow-right</v-icon>
        </v-btn>
      </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
export default {
  name: "IndicatorsCard",
  props: {
    card: { type: Object, required: true },
    isEmptyState: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["currentPlanIsBasicFree", "hasConectaControl", "company"]),
  },
  methods: {
    getCountUsers(card) {
      return card.usersCount ? card.usersCount : `${card.percentValues}%`;
    },
    setPath(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push({ path: path });
      }
    },

    execute(card, path, title) {
      const { main_domain } = this.company;
      const analytics = getAnalytics();
      if (path.includes("/billing/upgrade")) {
        logEvent(analytics, "go_to_self_checkout", {
          click: card,
          main_domain: this.mainDomain,
          email: this.currentUser.email,
          description: `Clicou no botão 'Fazer upgrade' dos cards de indicadores (dashboard)`,
        });
        return;
      }
      logEvent(analytics, "navigated_by", {
        card,
        path,
        title,
        navigation_by: "indicators_cards",
        main_domain,
      });

      this.setPath(path);
    },
  },
};
</script>

<style>
.cards {
  border: 0.95px solid #ebebeb !important;
  border-radius: 8.48px !important;
  box-shadow: 0px 5.71px 28.56px 0px #0000000f !important;
}
.cards-plan-gradient {
  background-color: #5b36c3;
  background-image: linear-gradient(#5b36c3, #e2d8ff);
  border: 0.95px solid #ebebeb;
}
.card-font {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.96px;
  text-align: left;
}
.card-subtitle {
  width: 65px !important;
  height: 18px !important;
  top: 1px !important;
  padding: 0px 1px 0px 5px !important;
  border-radius: 8px !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16.96px !important;
}
.card-sub-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.96px;
  text-align: left;
  color: #8a8a8a;
}
.card-user-photo {
  width: 28px;
  height: 28px;
  border: 1.4px solid #ffffff;
  box-shadow: 0px 2.8px 2.8px 0px #00000014;
  border-radius: 50%;
}
.card-user-photo-offset {
  margin-left: -6px;
}
.card-user-total-length {
  width: 28px;
  height: 28px;
  background: #5d43a6a6 60% !important;
  border: 1.4px solid #ffffff !important;
  box-shadow: 0px 2.8px 2.8px 0px #00000014 !important;
  border-radius: 50% !important;
  font-family: Manrope !important;
  font-size: 10.5px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  text-align: left !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  color: white;
}
.btns {
  color: #5b36c3 !important;
  min-width: 146px !important;
  height: 32px !important;
}
.text-empty-state {
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: left !important;
}
</style>
