var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"cards pb-3",attrs:{"elevation":"0","height":"184px","width":"212px","max-width":"212px"}},[_c('v-card-title',{style:(`color: ${_vm.card.isEmpty ? '#979797' : ''}`)},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"30","color":`${_vm.card.isEmpty ? '#979797' : '#5B36C3'}`}},[_vm._v(" "+_vm._s(_vm.card.icon)+" ")]),_c('span',{staticClass:"card-font",staticStyle:{"color":"#696969 !important"}},[_vm._v(" "+_vm._s(_vm.card.title)+" ")])],1),_c('v-card-subtitle',{staticClass:"card-font py-0"},[_c('div',{staticClass:"d-flex mt-5"},[_c('span',{staticClass:"mr-2",style:(`
          height: 29px;
          font-family: Montserrat;
          font-size: 24px;
          ${_vm.card.isEmpty ? 'color: #979797' : 'color: black;'}`)},[_vm._v(" "+_vm._s(_vm.isEmptyState ? _vm.card.emptyStateDefaultValue : _vm.getCountUsers(_vm.card))+" ")]),_c('v-chip',{staticClass:"card-subtitle",style:(`color: ${_vm.card.isEmpty ? '#979797' : '#696969'}`),attrs:{"color":"#DEFFE2"}},[_vm._v(_vm._s(_vm.$t("common.users")))])],1)]),(_vm.card.isEmpty)?_c('v-card-text',{staticClass:"py-0 d-flex justify-center align-center flex-column"},[_c('p',{staticClass:"py-0 my-0 card-font",style:(`color: ${_vm.card.isEmpty ? '#979797' : ''}`)},[_vm._v(" "+_vm._s(_vm.card.description)+" ")]),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('v-btn',{staticClass:"text-none text-body-2 font-weight-medium pa-0 mx-0 ma-0 btns",attrs:{"color":"#F1E1F7","elevation":"0"},on:{"click":function($event){return _vm.card.emptyAction()}}},[_c('span',{staticClass:"text-empty-state ml-3"},[_vm._v(_vm._s(_vm.$t(`action.${_vm.card.emptyActionText}`))+" ")]),_c('v-icon',{staticClass:"mx-3",attrs:{"right":"","size":"16"}},[_vm._v("ph-arrow-right")])],1)],1)]):_c('v-card-text',{staticClass:"py-0"},[_c('p',{staticClass:"py-0 my-0 card-font",style:(`color: ${_vm.card.isEmpty ? '#979797' : ''}`)},[_vm._v(" "+_vm._s(_vm.card.description)+" ")]),(_vm.card.percentValues)?_c('div',{staticStyle:{"position":"absolute","bottom":"6px","width":"84%"}},[_c('v-progress-linear',{staticClass:"white--text",staticStyle:{"margin-bottom":"7px !important"},attrs:{"color":`${_vm.card.isEmpty ? '#979797' : '#5B36C3'}`,"value":_vm.card.percentValues,"height":"5","rounded":""}}),_c('span',{staticClass:"card-sub-description",staticStyle:{"font-family":"Montserrat"},domProps:{"innerHTML":_vm._s(_vm.card.subDescription)}})],1):(!!_vm.card.users?.length)?_c('div',{staticClass:"d-flex align-start justify-start",staticStyle:{"position":"absolute","bottom":"5px"}},_vm._l((_vm.card.users),function(user,index){return _c('div',{key:index},[(index < 5)?_c('img',{class:[
            'card-user-photo',
            { 'card-user-photo-offset': index > 0 },
          ],attrs:{"src":user.photo}}):_vm._e(),(index === 5)?_c('div',{staticClass:"card-user-total-length white--text d-flex justify-center align-center font-weight-medium"},[_vm._v(" +"+_vm._s(_vm.card.users.length - 1)+" ")]):_vm._e()])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }